import * as React from "react";
import { navigate } from "gatsby-link";

// markup
const NotFoundPage = () => {
  React.useEffect(() => {
    navigate("/");
  });

  return null;
};

export default NotFoundPage;
